import React from 'react';
import PropTypes from 'prop-types';
import { NodeDetailsWrapper, InfoModalButton } from './styles';

const NodeDetails = ({ selectedNode, onClose }) => {
  if (!selectedNode) return null;

  return (
    <NodeDetailsWrapper>
      <h2 style={{ margin: '0 0 15px 0', fontSize: '20px' }}>{selectedNode.name}</h2>
      <p><strong>Entity Type:</strong> {selectedNode.label}</p>
      <p><strong>Legal Source:</strong> {selectedNode.properties.legal_source || 'N/A'}</p>
      <InfoModalButton onClick={onClose} style={{ marginTop: '15px' }}>
        Close
      </InfoModalButton>
    </NodeDetailsWrapper>
  );
};

NodeDetails.propTypes = {
  selectedNode: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default NodeDetails;