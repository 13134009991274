import PropTypes from 'prop-types';
import { FaInfo, FaTimes, FaTwitter, FaEnvelope } from 'react-icons/fa';
import {
  InfoModalWrapper,
  InfoButton,
  InfoModalContent,
  InfoModalTitle,
  InfoModalText,
  FeedbackLink,
  /*InfoModalInput,*/
  /*InfoModalTextarea,*/
  /*InfoModalButton*/
} from './styles';
import ReactGA from 'react-ga4';

const InfoModal = ({
  isOpen,
  setIsOpen,
  feedbackEmail,
  setFeedbackEmail,
  feedbackText,
  setFeedbackText,
  onSubmitFeedback
}) => {
  const handleInfoButtonClick = () => {
    setIsOpen(!isOpen);
    ReactGA.event({
      category: 'Info',
      action: isOpen ? 'Close' : 'Open'
    });
  };

  /*const handleSubmitFeedback = () => {
    onSubmitFeedback();
    ReactGA.event({
      category: 'Feedback',
      action: 'Submit'
    });
  };*/

  return (
    <InfoModalWrapper>
      <InfoButton onClick={handleInfoButtonClick}>
        {isOpen ? <FaTimes size={16} /> : <FaInfo size={16} />}
      </InfoButton>

      {isOpen && (
        <InfoModalContent>
          <InfoModalTitle>About SF Government Graph</InfoModalTitle>
          <InfoModalText>
            This graph is the first complete map of the structure of San Francisco's government. All entities cite the legal authority that created them.
          </InfoModalText>
          
          <InfoModalTitle>How to Use</InfoModalTitle>
          <InfoModalText>
            • Click and drag to pan the graph<br />
            • Scroll to zoom in/out<br />
            • Click on a node to see details<br />
            • Use the search bar to find entities
          </InfoModalText>
          <InfoModalTitle>Feedback</InfoModalTitle>
          <InfoModalText>
            Feedback is highly appreciated! Hit me up:<br />
            <FeedbackLink href="https://x.com/m_atoms" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </FeedbackLink>
            <FeedbackLink href="mailto:feedback@civlab.org">
              <FaEnvelope />
            </FeedbackLink>
          </InfoModalText>
          {/* Leaving out feedback for now */}
          {/*
          <InfoModalTitle>Provide Feedback</InfoModalTitle>
          <InfoModalInput
            type="email"
            placeholder="Your email (optional)"
            value={feedbackEmail}
            onChange={(e) => setFeedbackEmail(e.target.value)}
          />
          <InfoModalTextarea
            placeholder="Your feedback..."
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
          <InfoModalButton onClick={handleSubmitFeedback}>
            Submit Feedback
          </InfoModalButton>
          */}
        </InfoModalContent>
      )}
    </InfoModalWrapper>
  );
};

InfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  feedbackEmail: PropTypes.string.isRequired,
  setFeedbackEmail: PropTypes.func.isRequired,
  feedbackText: PropTypes.string.isRequired,
  setFeedbackText: PropTypes.func.isRequired,
  onSubmitFeedback: PropTypes.func.isRequired,
};

export default InfoModal;