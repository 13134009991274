import styled, { keyframes, css } from 'styled-components';

// Define breakpoints
const breakpoints = {
  mobile: '480px',
};

// Define the color theme
export const theme = {
  primary: '#452F90', // Delta-violet (purple)
  primaryHover: '#5A3CB8', // Slightly brighter purple for hover effect
  text: '#333333',
  background: '#FFFFFF',
  lightGray: '#F0F0F0',
};

// Create a reusable button style
const buttonStyle = css`
  background-color: ${theme.primary};
  color: ${theme.background};
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.primaryHover};
  }
`;

export const GraphContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.mobile}) {
    height: calc(100vh - 120px); // Adjust for header and search bar
  }
`;

export const GraphHeaderWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: none;
  }
`;

export const GraphTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const GraphTitle = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
`;

export const LegendContent = styled.div`
  margin-top: 10px;
`;

export const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export const LegendWrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 180px);
  overflow-y: auto;

  @media (max-width: ${breakpoints.mobile}) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    max-height: none;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const SearchBarWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 90%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    bottom: 10px;
    width: calc(100% - 60px); // Shrink to make room for info icon
    left: 10px;
    transform: none;
    max-width: none;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  background-color: transparent; // Changed to transparent
  color: ${theme.text};
  box-sizing: border-box;

  &::placeholder {
    color: #aaa;
  }
`;

export const SearchResultsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const SearchResultItem = styled.li`
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${theme.lightGray};
  }
`;

export const NodeDetailsWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 10px;
  right: 10px;
  z-index: 10;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 50vh;
  overflow-y: auto;
  font-size: 16px;

  @media (min-width: ${breakpoints.mobile}) {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
    max-width: 300px;
    width: calc(100% - 40px);
    max-height: 80vh;
  }
`;

export const InfoModalWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    bottom: 10px;
    right: 10px;
  }
`;

export const InfoButton = styled.button`
  ${buttonStyle}
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const InfoModalContent = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 300px;
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;
  max-height: 80vh;
  overflow-y: auto;
  animation: ${fadeIn} 0.3s ease;

  @media (max-width: 768px) {
    width: 90vw;
    max-width: 350px;
    right: -10px;
  }
`;

export const InfoModalTitle = styled.h2`
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 15px;
  color: #452F90;
`;

export const InfoModalText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 15px;
  color: #333;
`;

export const InfoModalInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
`;

export const InfoModalTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
`;

export const InfoModalButton = styled.button`
  ${buttonStyle}
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

export const FeedbackLink = styled.a`
  color: ${theme.primary};
  margin-left: 10px;
  font-size: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: ${theme.primaryHover};
  }
`;