import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { GraphHeaderWrapper, GraphTitleContainer, GraphTitle, LegendContent, ToggleButton, InfoModalButton } from './styles';

const GraphHeader = ({ title, nodeCounts, colorScheme, activeFilter, onFilterClick }) => {
  const [isExpanded, setIsExpanded] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsExpanded(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <GraphHeaderWrapper>
      <GraphTitleContainer>
        <GraphTitle>{title}</GraphTitle>
        <ToggleButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <FaChevronUp color="#ED1865" /> : <FaChevronDown color="#ED1865" />}
        </ToggleButton>
      </GraphTitleContainer>
      {isExpanded && (
        <LegendContent>
          <p><strong>Total Entities: {nodeCounts.total - nodeCounts.other}</strong></p>
          {Object.entries(colorScheme).map(([key, color]) => (
            <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <button
                onClick={() => onFilterClick(key)}
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: color,
                  marginRight: '10px',
                  border: activeFilter === key ? '2px solid black' : 'none',
                  cursor: 'pointer',
                }}
              ></button>
              <span style={{ textTransform: 'capitalize', flex: 1 }}>{key}</span>
              <span style={{ marginLeft: '10px', fontWeight: 'bold' }}>
                {nodeCounts[key]}
              </span>
            </div>
          ))}
          {activeFilter && (
            <InfoModalButton
              onClick={() => onFilterClick(null)}
              style={{
                marginTop: '10px',
                padding: '5px 10px',
                background: '#452F90',
                color: 'white',
                border: 'none',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              Clear Filter
            </InfoModalButton>
          )}
        </LegendContent>
      )}
    </GraphHeaderWrapper>
  );
};

GraphHeader.propTypes = {
  title: PropTypes.string.isRequired,
  nodeCounts: PropTypes.object.isRequired,
  colorScheme: PropTypes.object.isRequired,
  activeFilter: PropTypes.string,
  onFilterClick: PropTypes.func.isRequired,
};

export default GraphHeader;