import SFGovGraph from './components/Graph/SFGovGraph';
import { useGraphData } from './hooks/useGraphData';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Analytics } from "@vercel/analytics/react";

function App() {

  useEffect(() => {
    ReactGA.initialize('G-3VH1K341MG');
    ReactGA.send({ hitType: 'pageview', page: '/' });
  }, []);

  const { graphData, loading, error } = useGraphData();

  return (
    <div className="App" style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      {loading && <div className="absolute inset-0 flex items-center justify-center">Loading...</div>}
      {error && <div className="absolute inset-0 flex items-center justify-center text-red-500">Error: {error.message}</div>}
      {!loading && !error && graphData && (
        <SFGovGraph data={graphData} />
      )}
      {!loading && !error && !graphData && <div className="absolute inset-0 flex items-center justify-center">No graph data available</div>}
      <Analytics /> {/* Vercel Analytics */}
    </div>
  );
}

export default App;