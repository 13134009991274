import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import GraphHeader from './GraphHeader';
import SearchBar from './SearchBar';
import NodeDetails from './NodeDetails';
import InfoModal from './InfoModal';
//import { forceConfig } from './GraphControls';
import { GraphContainer, InfoModalWrapper } from './styles';
import { submitFeedback } from '../../services/feedback';
import ReactGA from 'react-ga4';
//import { forceSimulation, forceLink, forceManyBody, forceCenter, forceCollide } from 'd3-force';
import { forceLink, forceManyBody, forceCenter, forceCollide } from 'd3-force';

// Define node color scheme
const colorScheme = {
  elected: '#ED1865',  // Plasmagenta
  commission: '#9B287B',  // Nebula
  advisory: 'rgba(155, 40, 123, 0.8)',  // Nebula, lower opacity
  department: '#452F90',  // Delta-violet
  other: '#888888'  // Light grey
};

// Define the SFGovGraph component
const SFGovGraph = ({ data }) => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [hoveredNode, setHoveredNode] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [feedbackEmail, setFeedbackEmail] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [activeFilter, setActiveFilter] = useState(null);
  const fgRef = useRef();

  const initializeForces = useCallback((fg) => {
    // Custom force configuration
    fg.d3Force('link', forceLink().id(d => d.id).distance(50))
      .d3Force('charge', forceManyBody().strength(-150))
      .d3Force('center', forceCenter())
      .d3Force('collision', forceCollide().radius(25));
  }, []);

  useEffect(() => {
    if (fgRef.current) {
      initializeForces(fgRef.current);
    }
  }, [initializeForces]);

  /*useEffect(() => {
    console.log('SFGovGraph rendered', { data, forceConfig });
  }, [data]);*/

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const graphData = useMemo(() => {
    const nodes = data.entities.map(entity => ({
      ...entity,
      connections: 0,
    }));

    const links = data.relationships.map(rel => ({
      source: rel.source,
      target: rel.target,
      type: rel.type,
    }));

    links.forEach(link => {
      const sourceNode = nodes.find(node => node.id === link.source);
      const targetNode = nodes.find(node => node.id === link.target);
      if (sourceNode) sourceNode.connections++;
      if (targetNode) targetNode.connections++;
    });

    const maxConnections = Math.max(...nodes.map(node => node.connections));
    nodes.forEach(node => {
      node.size = 6 + (node.connections / maxConnections) * 7;
      node.maxConnections = maxConnections;
    });

    // Calculate node type counts
    const nodeCounts = {
      total: nodes.length,
      elected: 0,
      commission: 0,
      advisory: 0,
      department: 0,
      other: 0
    };

    nodes.forEach(node => {
      const label = node.label.toLowerCase();
      if (label.includes('elected')) {
        nodeCounts.elected++;
      } else if (label.includes('commission')) {
        nodeCounts.commission++;
      } else if (label.includes('advisory')) {
        nodeCounts.advisory++;
      } else if (label.includes('department')) {
        nodeCounts.department++;
      } else {
        nodeCounts.other++;
      }
    });

    // Apply filter if activeFilter is set
    const filteredNodes = activeFilter
      ? nodes.filter(node => {
          const label = node.label.toLowerCase();
          return label.includes(activeFilter);
        })
      : nodes;

    const filteredLinks = activeFilter
      ? links.filter(link => 
          filteredNodes.some(node => node.id === link.source) &&
          filteredNodes.some(node => node.id === link.target)
        )
      : links;

    return { nodes: filteredNodes, links: filteredLinks, nodeCounts };
  }, [data, activeFilter]);

  /// Node Canvas Object ///
  const nodeCanvasObject = useCallback((node, ctx, globalScale) => {
    const isHovered = node === hoveredNode;
    const isSelected = node === selectedNode;
    const isConnected = (hoveredNode || selectedNode) && (
      node.id === (hoveredNode || selectedNode).id || 
      graphData.links.some(link => 
        (link.source.id === (hoveredNode || selectedNode).id && link.target.id === node.id) ||
        (link.target.id === (hoveredNode || selectedNode).id && link.source.id === node.id)
      )
    );

    // Determine node color based on label
    let nodeColor = colorScheme.other;
    if (node.label.toLowerCase().includes('elected')) {
      nodeColor = colorScheme.elected;
    } else if (node.label.toLowerCase().includes('commission')) {
      nodeColor = colorScheme.commission;
    } else if (node.label.toLowerCase().includes('department')) {
      nodeColor = colorScheme.department;
    } else if (node.label.toLowerCase().includes('advisory')) {
      nodeColor = colorScheme.advisory;
    }

    // Function to brighten color for hover/select effect
    const brightenColor = (color, factor = 1.2) => {
      const rgb = color.startsWith('rgba') 
        ? color.match(/[\d.]+/g).map(Number) 
        : hexToRgb(color);
      return `rgba(${Math.min(255, Math.floor(rgb[0] * factor))}, 
                 ${Math.min(255, Math.floor(rgb[1] * factor))}, 
                 ${Math.min(255, Math.floor(rgb[2] * factor))}, 
                 ${rgb[3] || 1})`;
    };

    // Draw node
    ctx.beginPath();
    ctx.arc(node.x, node.y, node.size, 0, 2 * Math.PI, false);
    
    // Apply color based on node type and interaction state
    if (isHovered || isSelected) {
      ctx.fillStyle = brightenColor(nodeColor);
    } else if (isConnected) {
      ctx.fillStyle = brightenColor(nodeColor, 1.1);
    } else if (hoveredNode || selectedNode) {
      ctx.fillStyle = `rgba(${hexToRgb(nodeColor).join(',')}, 0.15)`;
    } else {
      ctx.fillStyle = nodeColor;
    }
    ctx.fill();

    // Text rendering
    const baseFontSize = 10;
    const minFontSize = 6;
    const maxFontSize = 12;
  
    // Calculate font size based on connections
    const connectionScale = node.connections / node.maxConnections;
    let fontSize = baseFontSize + (connectionScale * 2); // Adjust the multiplier (2) to control the scaling effect
  
    // Adjust font size for hover/select state
    if (isHovered || isSelected) {
      fontSize += 2; // Increase font size by 2 pixels when hovered or selected
    }
  
    // Clamp font size between min and max
    fontSize = Math.max(minFontSize, Math.min(maxFontSize, fontSize));

    // Calculate text opacity based on zoom level and hover/select state
    const minZoom = 1.0;
    const maxZoom = 3.0;
    let zoomOpacity = (globalScale - minZoom) / (maxZoom - minZoom);
    zoomOpacity = Math.max(0, Math.min(1, zoomOpacity));

    // Determine hover/select opacity
    let textOpacity;
    if (isHovered || isSelected) {
      textOpacity = 1; // Full opacity for hovered or selected nodes
    } else if (isConnected) {
      textOpacity = Math.max(zoomOpacity, 0.7); // At least 70% opacity for connected nodes
    } else if (hoveredNode || selectedNode) {
      textOpacity = Math.min(zoomOpacity, 0.1); // Lower of zoom opacity or 0.1 for non-connected nodes when a node is hovered/selected
    } else {
      textOpacity = zoomOpacity; // Normal zoom-based opacity for other cases
    }

    // Determine the label to display
    let displayLabel = node.name;
    if (node.label.toLowerCase() === 'employee' && 
        (node.name.includes('Executive') || node.name.includes('Director'))) {
      displayLabel = 'Director';
    }

    // Render text if zoomed in enough or node is hovered/selected/connected
    if (globalScale > minZoom || isHovered || isSelected || isConnected) {
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = `${fontSize}px Arial`; // Set the font with the calculated size

      // Adjust text color based on hover/select state
      const baseColor = isHovered || isSelected ? '#1a1a1a' : '#333333'; // Darker grey for hover/select
      const [r, g, b] = hexToRgb(baseColor);
      ctx.fillStyle = `rgba(${r}, ${g}, ${b}, ${textOpacity})`;

      // Position text below the node
      const textY = node.y + node.size + fontSize / 2;
      ctx.fillText(displayLabel, node.x, textY);
    }
  }, [hoveredNode, selectedNode, graphData]);

  // Helper function to convert hex color to RGB
  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : [0, 0, 0];
  };

  const linkCanvasObject = useCallback((link, ctx) => {
    const start = link.source;
    const end = link.target;
    const isHighlighted = (hoveredNode && (link.source.id === hoveredNode.id || link.target.id === hoveredNode.id)) ||
                          (selectedNode && (link.source.id === selectedNode.id || link.target.id === selectedNode.id));

    // Calculate the direction vector
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    const length = Math.sqrt(dx * dx + dy * dy);

    // Normalize the direction vector
    const unitDx = dx / length;
    const unitDy = dy / length;

    // Calculate the position of the direction indicator (80% along the link)
    const indicatorX = start.x + unitDx * length * 0.8;
    const indicatorY = start.y + unitDy * length * 0.8;

    // Draw the main link
    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    
    // Determine link color based on hover and selected states
    ctx.strokeStyle = hoveredNode || selectedNode
      ? (isHighlighted ? 'rgba(150, 150, 150, 0.8)' : 'rgba(150, 150, 150, 0.05)')
      : 'rgba(150, 150, 150, 0.8)';
    ctx.lineWidth = 1;
    ctx.stroke();

    // Draw the direction indicator (arrow)
    const arrowSize = 3;
    const arrowAngle = Math.PI / 6; // 30 degrees

    ctx.beginPath();
    ctx.moveTo(indicatorX, indicatorY);
    ctx.lineTo(
      indicatorX - arrowSize * Math.cos(arrowAngle) * unitDx + arrowSize * Math.sin(arrowAngle) * unitDy,
      indicatorY - arrowSize * Math.cos(arrowAngle) * unitDy - arrowSize * Math.sin(arrowAngle) * unitDx
    );
    ctx.moveTo(indicatorX, indicatorY);
    ctx.lineTo(
      indicatorX - arrowSize * Math.cos(arrowAngle) * unitDx - arrowSize * Math.sin(arrowAngle) * unitDy,
      indicatorY - arrowSize * Math.cos(arrowAngle) * unitDy + arrowSize * Math.sin(arrowAngle) * unitDx
    );
    ctx.lineWidth = 1.5;
    ctx.stroke();
  }, [hoveredNode, selectedNode]);

  // Make the entire node clickable
  const nodePointerAreaPaint = useCallback((node, color, ctx) => {
    ctx.beginPath();
    ctx.arc(node.x, node.y, node.size, 0, 2 * Math.PI, false);
    ctx.fillStyle = color;
    ctx.fill();
  }, []);

  // format the link label
  const formatLinkLabel = useCallback((link) => {
    return link.type === "department_head_of" ? "department head" : link.type;
  }, []);

  // function to handle search
  const handleSearch = useCallback((term) => {
    if (term.length > 0) {
      return graphData.nodes.filter(node =>
        node.name.toLowerCase().includes(term.toLowerCase())
      );
    }
    return [];
  }, [graphData]);

  // handle search result selection
  const handleSearchResultClick = (node) => {
    setSelectedNode(node);
    ReactGA.event({
      category: 'Search',
      action: 'Result Click',
      label: node.name
    });
  };

  const handleSubmitFeedback = async () => {
    try {
      await submitFeedback(feedbackEmail, feedbackText);
      alert('Feedback submitted successfully!');
      setShowInfoModal(false);
      setFeedbackEmail('');
      setFeedbackText('');
    } catch (error) {
      alert('Failed to submit feedback. Please try again.');
    }
  };

  const handleFilterClick = (filterType) => {
    setActiveFilter(activeFilter === filterType ? null : filterType);
    ReactGA.event({
      category: 'Filter',
      action: 'Click',
      label: filterType || 'Clear'
    });
  };

  const handleNodeClick = (node) => {
    setSelectedNode(node);
    ReactGA.event({
      category: 'Node',
      action: 'Click',
      label: node.name
    });
  };

  return (
    <GraphContainer>
      <GraphHeader 
        title="SF Government Graph"
        nodeCounts={graphData.nodeCounts}
        colorScheme={colorScheme}
        activeFilter={activeFilter}
        onFilterClick={handleFilterClick}
      />
      <ForceGraph2D
        ref={fgRef}
        graphData={graphData}
        nodeLabel={null}
        nodeCanvasObject={nodeCanvasObject}
        nodeCanvasObjectMode={() => 'replace'}
        nodePointerAreaPaint={nodePointerAreaPaint}
        linkSource="source"
        linkTarget="target"
        linkCanvasObject={linkCanvasObject}
        linkCanvasObjectMode={() => 'replace'}
        linkLabel={formatLinkLabel}
        width={dimensions.width}
        height={dimensions.height}
        maxZoom={5}
        backgroundColor="#f0f0f0"
        linkColor={() => 'rgba(200, 200, 200, 0.2)'}
        nodeRelSize={10}
        linkWidth={0.5}
        forceEngine="d3"
        cooldownTime={Infinity}
        d3AlphaMin={0}
        d3AlphaDecay={0.01}
        d3VelocityDecay={0.3}
        onEngineStop={() => {}} // Empty function to prevent automatic reheating
        onEngineStart={() => console.log('Force simulation has started')}
        onNodeHover={setHoveredNode}
        onNodeClick={handleNodeClick}
      />
      <SearchBar
        onSearch={handleSearch} 
        onSearchResultClick={handleSearchResultClick} 
      />
      
      {/* Node Info Text Box */}
      <NodeDetails 
        selectedNode={selectedNode} 
        onClose={() => setSelectedNode(null)} 
      />
      <InfoModalWrapper>
        <InfoModal 
          isOpen={showInfoModal}
          setIsOpen={setShowInfoModal}
          feedbackEmail={feedbackEmail}
          setFeedbackEmail={setFeedbackEmail}
          feedbackText={feedbackText}
          setFeedbackText={setFeedbackText}
          onSubmitFeedback={handleSubmitFeedback}
        />
      </InfoModalWrapper>
    </GraphContainer>
  );
};

export default SFGovGraph;