// src/services/api.js
export const fetchGraphData = async () => {
  try {
    const response = await fetch('/api/neo4j-proxy', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
          MATCH (n)
          OPTIONAL MATCH (n)-[r]->(m)
          WITH n, r, m
          RETURN 
            collect(distinct {
              id: id(n), 
              label: labels(n)[0], 
              name: n.name, 
              properties: properties(n)
            }) as entities,
            [rel IN collect(distinct {
              source: id(startNode(r)), 
              target: id(endNode(r)), 
              type: type(r)
            }) WHERE rel.source IS NOT NULL AND rel.target IS NOT NULL AND rel.type IS NOT NULL] as relationships
        `
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    //console.log('Raw API Response:', JSON.stringify(data, null, 2));

    if (!Array.isArray(data) || data.length === 0) {
      throw new Error('Unexpected data format received from API');
    }

    const firstRecord = data[0];
    if (!firstRecord || !firstRecord._fields || firstRecord._fields.length < 2) {
      throw new Error('Unexpected data structure received from API');
    }

    const result = {
      entities: firstRecord._fields[0].map(entity => ({
        ...entity,
        id: entity.id.low // Use the 'low' property of the Neo4j Integer
      })),
      relationships: firstRecord._fields[1].map(rel => ({
        ...rel,
        source: rel.source.low, // Use the 'low' property of the Neo4j Integer
        target: rel.target.low // Use the 'low' property of the Neo4j Integer
      }))
    };

    //console.log('Processed Graph Data:', JSON.stringify(result, null, 2));

    return result;
  } catch (error) {
    console.error('Error fetching graph data:', error);
    throw error;
  }
};