import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SearchBarWrapper, SearchInput, SearchResultsList, SearchResultItem } from './styles';

const SearchBar = ({ onSearch, onSearchResultClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const wrapperRef = useRef(null);

  const handleSearch = (term) => {
    setSearchTerm(term);
    const results = onSearch(term);
    setSearchResults(results);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SearchBarWrapper ref={wrapperRef}>
      <SearchInput
        type="text"
        placeholder="Search nodes..."
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
      />
      {searchResults.length > 0 && (
        <SearchResultsList>
          {searchResults.map(node => (
            <SearchResultItem
              key={node.id}
              onClick={() => {
                onSearchResultClick(node);
                setSearchTerm('');
                setSearchResults([]);
              }}
            >
              {node.name}
            </SearchResultItem>
          ))}
        </SearchResultsList>
      )}
    </SearchBarWrapper>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onSearchResultClick: PropTypes.func.isRequired,
};

export default SearchBar;